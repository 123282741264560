import loadMore from '../../03-molecules/load-more/load-more.js';
import triggerResize from '../../../assets/scripts/helpers/resize-trigger.js';
import scrollToElement from '../../../assets/scripts/helpers/scroll-to-element.js';


export default function (tabCordion) {
  /**
   * This functionality is only needed if the tabCordion contains a select box.
   * The accordion and the only-tabs variants work without JS.
   */

  // ignore nested accordions
  const tabs = tabCordion.getElementsByClassName("js-tabcordion-item");
  const selectBox = tabCordion.querySelector(".js-tab-select");
  const linkAnchor = window.location.hash
    ? window.location.hash.substring(1)
    : "";
  let countLoadMoreClicks = 1;
  let initFilterFromUrlParam = false;

  /**
   * find closest element with a certain class (transversing up the dom)
   */
  function closest(el, cls) {
    // console.log("** closest **", el, cls);

    while (el && el !== document) {
      if (el.classList.contains(cls)) {
        // console.log("found el:", el);
        return el;
      }
      el = el.parentNode;
    }
    return null;
  }

  /**
   * show or hide custom filters, depending on selected tab
   */
  function initFilter() {
    // console.log("** initFilter **");

    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      //console.log("param ** ", key, "=", value);

      // value might be comma-separated
      const values = value.split(",");

      // filter exist as filter__tags with data-name...
      const tagFilter = document.querySelectorAll(
        "[data-name='" + key + "']"
      )[0];

      if (tagFilter) {
        values.forEach(function (v) {
          const tags = tagFilter.getElementsByClassName("js-tag-trigger");
          for (let tag of tags) {
            if (tag.textContent.trim() === v) {
              tag.classList.add("is-active");
              break;
            }
          }
        });
      } else {
        // or select with a name
        let selectFilter = document.querySelectorAll(
          "select[name='" + key + "']"
        )[0];

        if (selectFilter) {
          for (let i = 0; i < selectFilter.options.length; i++) {
            let option = selectFilter.options[i];

            if (selectFilter.multiple) {
              values.forEach(function (v) {
                if (option.value.trim() === v) {
                  option.selected = true;
                }
              });
            } else {
              if (option.value.trim() === values[0]) {
                option.selected = true;
                break;
              }
            }
          }
        }
      }
    });

    initFilterFromUrlParam = true;
  }

  /**
   * show or hide custom filters, depending on selected tab
   */
  function toggleCustomFilters(tab) {
    // check url parameter and set select/activate corresponding filter
    if (!initFilterFromUrlParam) {
      initFilter();
    }

    if (tabCordion.querySelector(".js-content-filters")) {
      // new behaviour:

      const filters = tabCordion.querySelector(".js-content-filters");

      Array.prototype.forEach.call(
        filters.getElementsByClassName("js-content-filter--custom"),
        (el) => {
          if (el.classList.contains("js-content-filter--custom--" + tab)) {
            el.classList.add("is-active");
          } else {
            el.classList.remove("is-active");
          }
        }
      );
    } else {
      // fallback...
      const filter = $(".filter");
      filter.find(".js-custom-filter").hide();
      if (tab) {
        filter.find(".js-custom-filter--" + tab).show();
      }
    }
  }

  /**
    Mobile: get the selection of the select box and activate the right tab.
	 */
  function handleSelectTab() {
    const i = this.selectedIndex + 1;
    const el = this.parentElement.querySelector(
      "input.js-tabcordion-item:nth-of-type(" + i + ")"
    );
    // console.debug("jgdebug handleSelectTab", el);
    el.click();
    triggerResize();
  }

  /**
   * For each tab generate an option node and insert it in the select box of the current TabCordion.
   * Overwrite the title attribute of all labels (tabs) - (overwrite the default values which the CK-Editor sets).
   * @param tab
   */
  function addTabToSelectBox(tab) {
    const labelText = tab.nextElementSibling.textContent;
    const label = document.createTextNode(labelText);
    tab.nextElementSibling.setAttribute("title", labelText);

    if (selectBox) {
      const option = document.createElement("option");
      option.value = tab.value;
      option.appendChild(label);
      selectBox.appendChild(option);

      if (tab.checked) {
        selectBox.value = tab.value;
      }
    }
  }

  function renderInitialData(data, buttonWrapper) {
    // console.log("** renderInitialData **", data, buttonWrapper);
    const contentWrapper = buttonWrapper.parentNode;
    if (buttonWrapper.classList.contains("js-load-more-search")) {
      // console.log("jgdebug renderInitialData-Search");
      const hideFilters = buttonWrapper.classList.contains("hide-filter");
      const typeLabel = buttonWrapper.getAttribute("data-label");
      const dataForTab = buttonWrapper.getAttribute("data-for-tab");
      const gridSpanFirst = document.querySelector("#grid-1");
      const gridSpanSecond = document.querySelector("#grid-2");
      const gridSpanThird = document.querySelector("#grid-3");
      const filterButton = document.querySelector(".js-filter-button");
      if (hideFilters === true) {
        // console.debug("jgdebug hide Filters");
        if (gridSpanFirst !== null) {
          gridSpanFirst.classList.add("hide-on--all");
        }
        if (gridSpanSecond !== null) {
          gridSpanSecond.classList.add("hide-on--all");
        }
        if (gridSpanThird !== null) {
          gridSpanThird.classList.remove("span-8");
          gridSpanThird.classList.add("span-10");
        }
        if (filterButton !== null) {
          filterButton.classList.add("hide-on--all");
        }
      } else {
        // console.debug("jgdebug show Filters");
        if (gridSpanFirst !== null) {
          gridSpanFirst.classList.remove("hide-on--all");
        }
        if (gridSpanSecond !== null) {
          gridSpanSecond.classList.remove("hide-on--all");
        }
        if (gridSpanThird !== null) {
          gridSpanThird.classList.add("span-8");
          gridSpanThird.classList.remove("span-10");
        }
        if (filterButton !== null) {
          filterButton.classList.remove("hide-on--all");
        }
      }
      const searchResultSelector = `strong#searchResults--${dataForTab}`;
      const searchResults = document.querySelector(searchResultSelector);
      if (searchResults !== null) {
        searchResults.innerHTML = data.searchresult;
      }
      const searchTextSelector = `strong#searchText--${dataForTab}`;
      const searchText = document.querySelector(searchTextSelector);
      if (searchText !== null) {
        searchText.innerHTML = '"' + data.searchText + '" in ' + typeLabel;
      }
      const searchPageResultTextSelector = `p.search-page__results-count--${dataForTab}`;
      const searchPageResultText = document.querySelector(searchPageResultTextSelector);
      if (data.showMore === false) {
        buttonWrapper.classList.remove("loading");
        if (searchPageResultText !== null) {
          searchPageResultText.classList.remove("hide");
        }
        removeInfiniteScroll();
        // console.log("jgdebug showMore false: ", data);
        //return;
      }
      let searchResultsMarkup = ``;
      if (!buttonWrapper.hasAttribute("data-page-number")) {
        data.results.forEach((result) => {
          if (result.subtype) {
            let resultSubtype = result.subtype.toLowerCase();
            let resultSubTypeLabel = typeLabel;
            const contentTemplate = `<a href="${result._link_}" class="list ">
                <div class="card__text">
                  <div>
                    <h3>${result.title}</h3>
                    <p>${result.leadin}</p>
                  </div>
                </div>
                <div class="card__meta">
                  <small class="context-label context-label--${resultSubtype}">${resultSubTypeLabel}</small>
                </div>
              </a>
            `;
            searchResultsMarkup += contentTemplate;
          } else {
            const authorjobtitle = result.authorjobtitle
              ? result.authorjobtitle
              : "";
            const address = result.address ? result.address : "";
            const link = result.link ? result.link : "";
            const phonenumber = result.phonenumber
              ? "Tel: " + result.phonenumber
              : "";
            const faxnumber = result.faxnumber
              ? "Fax: " + result.faxnumber
              : "";
            const emailaddress = result.emailaddress
              ? "Mail: " + result.emailaddress
              : "";
            const portrait = result.authorimage
              ? `
              <div class="portrait">
                  <img data-src="${result.authorimage.patternurl}" data-dimensions="0.75" alt="" width="auto" height="auto">
              </div>
            `
              : ``;
            const contentTemplate = `<div class="details--wrapper">
                    <div class="contact--detail-container">
                        <div class="contact-name--container">
                            <div class="contact-name">${result.authorname}</div>
                            <div class="shoptitle">${authorjobtitle}</div>
                            <div class="organisation"></div>
                        </div>
                        <div class="remote">
                            <div class="postal">
                                <div class="address">${address}</div>
                                <div class=" link">
                                  <a href="${link}">Link</a>
                                </div>
                            </div>
                            <div class="postal">
                              <div class="phone">
                                  <a href="tel:${result.phonenumber}">${phonenumber}</a>
                              </div>
                              <div class="fax">${faxnumber}</div>
                              <div class="mail">
                                  <a href="mailto:${result.emailaddress}">${emailaddress}</a>
                              </div>
                            </div>
                        </div>
                    </div>
                    ${portrait}
                  </div>
            `;
            searchResultsMarkup += contentTemplate;
          }
        });
      } else {
        console.debug(" jgdebug render result not necessary ...");
      }
      buttonWrapper.setAttribute("data-page-number", "1");
      buttonWrapper.classList.remove("loading");

      if (searchPageResultText !== null) {
        searchPageResultText.classList.remove("hide");
      }
      if (data.showMore === false) {
        buttonWrapper.classList.add("hide-on--all");
      } else {
        buttonWrapper.classList.remove("hide-on--all");
      }
      searchResultsMarkup += `${buttonWrapper.outerHTML}`;
      contentWrapper.removeChild(buttonWrapper);
      contentWrapper.insertAdjacentHTML("beforeend", searchResultsMarkup);
      // console.log("searchResultsMarkup", searchResultsMarkup);
      // trigger the loadMore module which will add click event to the new button
      if (contentWrapper.querySelector(".js-load-more-search") !== null) {
        loadMore(contentWrapper.querySelector(".js-load-more-search"));
      }
      triggerResize();
    } else {
      if (contentWrapper) {
        if (contentWrapper.classList.contains("newsjson")) {
          const dataInitUrl = buttonWrapper.getAttribute("data-init-url");
          const dataTab = buttonWrapper.getAttribute("data-for-tab");
          const isMansoryTab = contentWrapper.classList.contains("mansonry");
          const socialCardContainer = contentWrapper.querySelector(".social-cards-container");
          if (dataInitUrl !== null) {
            const urlParams = new URLSearchParams(dataInitUrl);
            // console.log("jgdebug urlParams", urlParams.get("tab"));
            if (urlParams.get("page") !== null) {
              const pageNumber = parseInt(urlParams.get("page"));
              const dataInitUrlLoadmore = dataInitUrl.replace(
                "page=" + pageNumber,
                "page=" + (pageNumber + 1)
              );
              buttonWrapper.setAttribute("data-init-url", dataInitUrlLoadmore);
            } else {
              const dataInitUrlLoadmore = dataInitUrl + "&page=2";
              buttonWrapper.setAttribute("data-init-url", dataInitUrlLoadmore);
            }
          }
          const socialTypes = ["facebook", "twitter", "linkedin", "youtube", "instagram"];
          data = JSON.parse(data);
          const cards = data.data.sort ((a, b) => {
            if (a.publishingdate === b.publishingdate) {
              const differenceinminA = parseInt(a.differenceinmin);
              const differenceinminB = parseInt(b.differenceinmin);
              return differenceinminA - differenceinminB;
            }
            return new Date(b.publishingdate) - new Date(a.publishingdate);
          });
          console.log("jgdebug newsjson", cards);
          // get html lang attribute
          const htmlLang = document.querySelector("html").getAttribute("lang");
          let minutesLabel = "minutes";
          let hoursLabel = "hours";
          let yesterdayLabel = "yesterday";
          if (htmlLang !== null && htmlLang !== undefined && htmlLang === "de") {
            minutesLabel = "Minuten";
            hoursLabel = "Stunden";
            yesterdayLabel = "gestern";
          }

          cards.forEach((card) => {
            const cardType = card.type;
            const cardLabel = card.label;
            let dataDimensions = "1.7777777777777777";
            let socialClass = "card--owcs";
            let socialTypeClass = "";
            let gridRowEndClass = "";
            let linkTarget = "_self";
            const cardWithImage = card.image !== null && card.image !== "" && card.image !== undefined ? true : false;
            if (cardWithImage) {
              gridRowEndClass = "col--2x";
            } else if (cardType == "News"){
              gridRowEndClass = "col--2x";
            } else if (cardType == "InternalBlog") {
              gridRowEndClass = "col--2x";
            } else if (cardType == "FrontBlogPost") {
              gridRowEndClass = "col--2x";
            }
            let cardTitle = "";
            // convert minutes to hours
            const differenceinmin = card.differenceinmin;
            let cardTime = card.publishingdate;
            if (differenceinmin && differenceinmin > 0) {
              if (differenceinmin && differenceinmin < 60) {
                cardTime = differenceinmin + " " + minutesLabel;
              } else if (differenceinmin && differenceinmin < 1440) {
                const hours = Math.floor(differenceinmin / 60);
                cardTime = hours + " " + hoursLabel;
              } else if (differenceinmin && differenceinmin >= 1440 && differenceinmin < 2880) {
                cardTime = " "  + yesterdayLabel;
              }
            }
            if (socialTypes.includes(cardType)) {
              linkTarget = "_blank";
              dataDimensions = "1.3333333333333333";
              if (cardType === "instagram") {
                dataDimensions = "1";
                gridRowEndClass = "col--2x";
              } else if (cardType === "facebook") {
                dataDimensions = "1";
                gridRowEndClass = "col--2x";
              }
              socialClass = "card--wallsio";
              socialTypeClass = "card--" + cardType;
            } else {
              cardTitle = `<h3>${card.title}</h3>`;
            }
            let contentTemplate = `<a href="${card.href}" class="card ${socialClass} ${gridRowEndClass} ${socialTypeClass}" data-target="card" target="${linkTarget}">`;
            if (cardWithImage) {
              contentTemplate += `<figure>
                  <img data-dimensions="${dataDimensions}" alt="${card.title}" title="${card.title}" width="auto" height="auto" src="${card.image}" fetchpriority="high" decoding="async">
                </figure>`;
            }
            let labelClass = cardType === "News" ? "press" : cardType.toLowerCase();
            let cardTextTemplate = `<div class="card__text">
                      <div>
                        ${cardTitle}
                        <p>
                          ${card.leadin}
                        </p>
                      </div>
                    </div>`;
            if (card.usermap !== null && card.usermap !== undefined && card.usermap !== "") {
              const authorPhotoURL = card.usermap.authorPhotoURL;
              const encodedPhoto = card.usermap.encodedPhoto;
              const usermapTitle = card.usermap.title !== null && card.usermap.title !== undefined && card.usermap.title !== "" ? card.usermap.title : "";
              const usermapCompany = card.usermap.omvCompany !== null && card.usermap.omvCompany !== undefined && card.usermap.omvCompany !== "" ? card.usermap.omvCompany : "";
              const authorImage = encodedPhoto !== null && encodedPhoto !== undefined && encodedPhoto !== "" ? `data:image/jpeg;base64,${encodedPhoto}` : authorPhotoURL;
              cardTextTemplate = `<div class="card__text">
                      <div>
                        <div class="card__author">
                            <figure class="author-image">
                              <img src="${authorImage}" data-dimensions="1" alt="" width="auto" height="auto"/>
                            </figure>
                          <div class="card__author-info">
                            <small>von <strong>${card.usermap.givenName} ${card.usermap.sn}</strong></small>
                            <small>${usermapTitle}</small>
                            <small>${usermapCompany}</small>
                          </div>
                        </div>
                        ${cardTitle}
                        <p>
                          ${card.leadin}
                        </p>
                      </div>
                    </div>`;
            }
            contentTemplate += `<div class="card__meta">
                    <small class="card__date">${cardTime}</small>
                    <div class="card__spacer"></div>
                    <small class="context-label context-label--${labelClass}">${cardLabel}</small>
                  </div>
                  ${cardTextTemplate}
                </a>`;

            if (isMansoryTab) {
              if (socialCardContainer !== null) {
                socialCardContainer.insertAdjacentHTML("beforeend", contentTemplate);
              }
            } else {
              contentWrapper.insertAdjacentHTML("beforeend", contentTemplate);
            }
          });
          const button = buttonWrapper.querySelector("button");
          if (button) {
            const showMore = data.showmore;
            if (showMore !== null && showMore !== undefined  && showMore === true) {
              if (button && button.classList.contains("hide")) {
                button.classList.remove("hide");
              }
            } else {
              button.classList.add("hide");
            }
          }
        } else {
          contentWrapper.removeChild(buttonWrapper);
          contentWrapper.insertAdjacentHTML("beforeend", data);

          // now check for a class "card--hero" and move that node one level higher in the DOM.
          const heroCard = contentWrapper.querySelector(
            ".card.card--card-style.card--hero"
          );
          if (heroCard) {
            let parent = heroCard.parentNode;
            parent.removeChild(heroCard);
            parent.parentNode.insertBefore(
              heroCard,
              parent.parentNode.childNodes[0]
            );
          }
          // trigger the loadMore module which will add click event to the new button
          loadMore(contentWrapper.querySelector(".js-load-more"));
        }
        triggerResize();
      }
    }
  }

  let throttleTimer;
  let currentPage = 1;
  const throttle = (callback, time) => {
    if (throttleTimer) return;

    throttleTimer = true;

    setTimeout(() => {
      callback();
      throttleTimer = false;
    }, time);
  };

  const handleInfiniteScroll = () => {
    throttle(() => {
      const endOfPage =
        window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
      if (endOfPage) {
        const activeTabContent = tabCordion.querySelector(
          ".tabcordion--tab-content.is-active"
        );
        if (activeTabContent !== null) {
          const buttonWrapperSearch = activeTabContent.querySelector(
            ".js-load-more-search"
          );
          if (buttonWrapperSearch !== null) {
            console.log("jgdebug load more content", buttonWrapperSearch);
            buttonWrapperSearch.querySelector("button").click();
            //buttonWrapperSearch.removeChild(buttonWrapperSearch);
          }
        }
      }
    }, 1000);
  };

  const removeInfiniteScroll = () => {
    window.removeEventListener("scroll", handleInfiniteScroll);
  };

  function loadInitialContent(tabcordionItem) {
    // console.log("** loadInitialContent **", tabcordionItem);

    let tabContent = tabCordion.querySelector(
      ".js-tab-content .js-tab-content--" + tabcordionItem.value
    );
    if (tabContent) {
      // new way:
      Array.prototype.forEach.call(
        tabCordion.getElementsByClassName("tabcordion--tab-content"),
        (el) => {
          el.classList.remove("is-active");
        }
      );
      tabContent.classList.add("is-active");
    } else {
      // fallback:
      tabContent = tabcordionItem.nextElementSibling.nextElementSibling;
    }

    if (!tabContent) {
      console.warn(
        "Error in tabcordion.loadInitialContent: Could not find tab content of tab " +
          tabcordionItem.value
      );
      return;
    }

    const searchContainer = document.querySelector(".view-toggle-search .content-filter-search");
    if (tabcordionItem.value === "wallsio-news" && searchContainer !== null) {
      searchContainer.style.display = "none";
    } else if (searchContainer !== null) {
      searchContainer.removeAttribute("style");
    }


    const buttonWrapper = tabContent.getElementsByClassName("js-load-more")[0];

    if (buttonWrapper) {
      let url = buttonWrapper.getAttribute("data-init-url");
      if (url !== null) {
        const urlParams = new URLSearchParams(url);
        if (urlParams.get("page") !== null && urlParams.get("page") !== "1") {
          return;
        }

        const cardSection = tabContent.querySelector(
          ".card-section__card-style"
        );
        if (
          $(".center-children").length > 0 &&
          $(".center-children").hasClass("loading") === false
        ) {
          if (cardSection !== null) {
            cardSection.classList.add("loading");
          }
        }
        //buttonWrapper.classList.add("loading");

        // prepend protocol, hostname and port to url
        //url = window.location.protocol + "//" + window.location.host + url;
        // console.log("loadInitialContent with " + url);



        fetch(url)
          .then((r) => r.text())
          .then((data) => renderInitialData(data, buttonWrapper))
          .then(() => {
            if (
              $(".center-children").length > 0 &&
              $(".center-children").hasClass("loading") === true
            ) {
              $(".center-children").removeClass("loading");
            }
            if (
              cardSection !== null &&
              cardSection.classList.contains("loading")
            ) {
              cardSection.classList.remove("loading");
            }
          })
          .catch((e) =>
            console.error("loadInitialContent: fetch returned error: " + e)
          );
      }
    }

    // load internal blogs
    const buttonWrapperInternalBlogs = document.getElementsByClassName(
      "js-load-internal-blog"
    )[0];
    if (buttonWrapperInternalBlogs) {
      let dataInitUrl = buttonWrapperInternalBlogs.getAttribute(
        "data-init-url"
      );

      if (dataInitUrl !== null) {
        dataInitUrl = dataInitUrl.replace("pageSize=6", "pageSize=10");
        dataInitUrl = dataInitUrl + "&section=intranetstartpage";
        //buttonWrapperInternalBlogs.classList.add("loading");

        // prepend protocol, hostname and port to url
        dataInitUrl =
          window.location.protocol + "//" + window.location.host + dataInitUrl;
        // console.log("jg debug loadInternalBlog Content with ", dataInitUrl);

        fetch(dataInitUrl)
          .then((r) => r.text())
          .then((data) => renderInitialData(data, buttonWrapperInternalBlogs))
          .then(() => numberOfSocials())
          .then(() => initIntranetLoadMore())
          .then(() => initSwiper())
          .catch((e) =>
            console.error("loadInternalBlogContent: fetch returned error: " + e)
          );
      }
    }

    //load search tabs
    const buttonWrapperSearch = tabContent.getElementsByClassName(
      "js-load-more-search"
    )[0];

    if (buttonWrapperSearch) {
      let url = buttonWrapperSearch.getAttribute("data-url");
      // console.log("jgdebug url Search:", buttonWrapperSearch);
      if (url !== null && url.indexOf("searchText=") > 0) {
        buttonWrapperSearch.classList.add("loading");

        // prepend protocol, hostname and port to url
        // url = window.location.protocol + "//" + window.location.host + url;
        // console.log("loadInitialContent with " + url);

        fetch(url)
          .then((r) => r.json())
          .then((data) => renderInitialData(data, buttonWrapperSearch))
          .then(() => {
            $(".center-children").removeClass("loading");
          })
          .catch((e) =>
            console.error("loadInitialContent: fetch returned error: " + e)
          );
      } else {
        const tabContentWrapper = document.querySelector(
          ".tabcordion--tab-content-wrapper"
        );
        if (tabContentWrapper !== null) {
          tabContentWrapper.classList.add("hide");
        }
        buttonWrapperSearch.style.display = "none";
      }
    }
  }

  /**
   * retrieve number of comments and likes
   */
  function numberOfSocials() {
    const internalBlogTabContent = document.querySelector(
      "#internalblog-content"
    );
    if (internalBlogTabContent) {
      Array.prototype.forEach.call(
        internalBlogTabContent.querySelectorAll(".card__social"),
        (socialCardItem) => {
          const assetId = socialCardItem.getAttribute("data-assetid");
          if (assetId) {
            fetchServiceUrls(
              socialCardItem,
              "likes",
              ".icon-text--like",
              assetId
            );
          }
          const commentIds = socialCardItem.getAttribute("data-commentids");
          if (commentIds) {
            fetchServiceUrls(
              socialCardItem,
              "comments",
              ".icon-text--comment",
              commentIds
            );
          }
        }
      );
    }
  }

  /**
   * fetch service urls
   */
  function fetchServiceUrls(socialCardItem, serviceType, cssSelector, assetId) {
    const fetchUrl = "/services/" + serviceType + "/status/" + assetId;
    // console.log("jgdebug serviceUrl", fetchUrl);
    fetch(fetchUrl)
      .then((resp) => {
        // If there are errors, such as a 503 error, proceed directly to the `finally` to try again:
        if (!resp.ok) return;
        return resp.json();
      })
      .then((data) => {
        if (data !== undefined) {
          const numberOfCounts = data.count;
          const targetDiv = socialCardItem.querySelector(cssSelector);
          if (targetDiv) {
            targetDiv.innerHTML = numberOfCounts;
          }
        }
      })
      .catch((e) =>
        console.error("numberOfSocials: fetch returned error: " + e)
      );
  }

  /**
   * Select the corresponding option in the select box to keep the tabs and the select box (for mobile) in sync
   */
  function handleTabSelect(e) {
    // console.log("** handleTabSelect **");

    toggleCustomFilters(e.target.value);

    $('input[type="radio"][value="' + e.target.value + '"]').prop(
      "checked",
      true
    );
    const index = [
      ...this.parentElement.getElementsByClassName("js-tabcordion-item"),
    ].indexOf(this);
    this.parentElement.getElementsByTagName("option")[index].selected =
      "selected";
    triggerResize();

    loadInitialContent(this);

    let internalBlogTabContent = document.querySelector(
      "#internalblog-content"
    );
    if (internalBlogTabContent) {
      countLoadMoreClicks = 1;
      const allCardsNodes = internalBlogTabContent.querySelectorAll(".card");
      // console.log('jgdebug allCardsNodes: ', allCardsNodes.length);
      if (allCardsNodes.length > 7) {
        for (let i = 7; i < allCardsNodes.length; i++) {
          const cardNode = allCardsNodes[i];
          cardNode.parentNode.removeChild(cardNode);
        }
      }
    }

    if (linkAnchor) {
      scrollToElement(this.nextElementSibling, 500, 500);
    }
  }

  function handleSocialFilterItem(e) {
    // console.log("** handleSocialFilterItem **");
    const socialFilterItems = document.querySelectorAll(".social-filter__item");
    const dataSocialFilter = this.getAttribute("data-filter");
    // console.log("jgdebug dataSocialFilter", dataSocialFilter);
    let wFilterValue = "";
    if (socialFilterItems) {
      Array.prototype.forEach.call(socialFilterItems, (socialFilterItem) => {
        if (dataSocialFilter === "all") {
          socialFilterItem.classList.remove("item--active");
        } else {
          if (socialFilterItem.getAttribute("data-filter") === "all") {
            socialFilterItem.classList.remove("item--active");
          } else {
            if (socialFilterItem.classList.contains("item--active")) {
              wFilterValue += socialFilterItem.getAttribute("data-filter") + ",";
            }
          }
        }
      });
    }
    if (this.classList.contains("item--active")) {
      this.classList.remove("item--active");
      wFilterValue = wFilterValue.replace(dataSocialFilter + ",", "");
    } else {
      this.classList.add("item--active");
      if (dataSocialFilter !== "all") {
        wFilterValue += dataSocialFilter + ",";
      }
    }
    // js-load-more data-for-tab="wallsio-news
    const socialButtonWrapper = document.querySelector(".js-load-more[data-for-tab='wallsio-news']");
    if (socialButtonWrapper) {
      const dataInitUrl = socialButtonWrapper.getAttribute("data-init-url");
      const urlParams = new URLSearchParams(dataInitUrl);
      let newDataInitUrl = "";
      if (wFilterValue !== "") {
         wFilterValue = wFilterValue.slice(0, -1);
        if (urlParams.get("wfilter") !== null) {
          newDataInitUrl = dataInitUrl.replace(urlParams.get("wfilter"), wFilterValue);
        } else {
          newDataInitUrl = dataInitUrl + "&wfilter=" + wFilterValue;
        }
      } else {
        if (urlParams.get("wfilter") !== null) {
          newDataInitUrl = dataInitUrl.replace("&wfilter=" + urlParams.get("wfilter"), "");
        } else {
          newDataInitUrl = dataInitUrl;
        }
      }

      // check if there is a page parameter
      if (urlParams.get("page") !== null) {
        newDataInitUrl = newDataInitUrl.replace(urlParams.get("page"), "1");
      }
      if (newDataInitUrl !== "") {
        socialButtonWrapper.setAttribute("data-init-url", newDataInitUrl);
        const intranetLoadMore = document.querySelector(".button--js-load-more-all");
        if (intranetLoadMore) {
          // console.log("jgdebug intranetLoadMore", intranetLoadMore);
          let activeTabContent = document.querySelector(".is-active");
          if (activeTabContent) {
            // console.log("jgdebug activeTabContent", activeTabContent);
            const socialCardContainer = activeTabContent.querySelector(".social-cards-container");
            if (socialCardContainer) {
              socialCardContainer.innerHTML = "";
            }
            countLoadMoreClicks = 1;
            fireButton(
              activeTabContent,
              false,
              countLoadMoreClicks,
              intranetLoadMore
            );
            intranetLoadMore.setAttribute("style", "display:block");
            document.querySelector(".button--js-new-archive-all").setAttribute("style", "display:none");
            let internalBlogTabContent = document.querySelector(
              "#internalblog-content"
            );
            if (internalBlogTabContent) {
              const allCardsNodes = internalBlogTabContent.querySelectorAll(".card");
              // console.log('jgdebug allCardsNodes: ', allCardsNodes.length);
              if (allCardsNodes.length > 7) {
                for (let i = 7; i < allCardsNodes.length; i++) {
                  const cardNode = allCardsNodes[i];
                  cardNode.parentNode.removeChild(cardNode);
                }
              }
            }
          }
        }
      }
      console.log("jgdebug newDataInitUrl", newDataInitUrl);
    }
  }

  /**
   * Select the corresponding option in the select box to keep the tabs and the select box (for mobile) in sync
   */
  function handleTabSelectSocial(e) {
    // console.log("** handleTabSelectSocial **");

    toggleCustomFilters(e.target.value);

    $('input[type="radio"][value="' + e.target.value + '"]').prop(
      "checked",
      true
    );
    const index = [
      ...this.parentElement.getElementsByClassName("js-tabcordion-item"),
    ].indexOf(this);
    this.parentElement.getElementsByTagName("option")[index].selected =
      "selected";
    triggerResize();


    loadInitialContent(this);

    let internalBlogTabContent = document.querySelector(
      "#internalblog-content"
    );
    if (internalBlogTabContent) {
      countLoadMoreClicks = 1;
      const allCardsNodes = internalBlogTabContent.querySelectorAll(".card");
      // console.log('jgdebug allCardsNodes: ', allCardsNodes.length);
      if (allCardsNodes.length > 7) {
        for (let i = 7; i < allCardsNodes.length; i++) {
          const cardNode = allCardsNodes[i];
          cardNode.parentNode.removeChild(cardNode);
        }
      }
    }

    if (linkAnchor) {
      scrollToElement(this.nextElementSibling, 500, 500);
    }
  }

  /**
   * When clicking tabs or accordion panels this function is called.
   * Since the tab content is display none by default we have to trigger resize when opening a tab to load containing images.
   * When opening/closing accordion panels we scroll to the panel to have the panel's content inside the viewport.
   */
  function handleClick() {
    // console.log("** handleClick **", this);

    triggerResize();

    // console.log("window.innerWidth:", window.innerWidth);
    // console.log("linkAnchor:", linkAnchor);
    // console.log("parent classList contains 'tabcordion--accordion':", this.parentElement.classList.contains("tabcordion--accordion"));

    if (
      window.innerWidth < 768 ||
      linkAnchor ||
      this.parentElement.classList.contains("tabcordion--accordion")
    ) {
      const closestTabcordion = closest(this, "tabcordion--accordion");
      const isNestedTabcordion = !!closest(
        closestTabcordion.parentElement,
        "tabcordion--accordion"
      );

      if (!isNestedTabcordion) {
        // console.log("scroll to:", this.nextElementSibling);
        scrollToElement(this.nextElementSibling, 500, 500);
      }
    }

    loadInitialContent(this);
  }

  if (selectBox) {
    // variant: only TabSelect
    Array.prototype.forEach.call(tabs, (tab) => {
      addTabToSelectBox(tab);
      if (tab.classList.contains("newsjson")) {
        tab.addEventListener("click", handleTabSelectSocial);
        // add click event to all social-filter__item
        const socialFilterItems = document.querySelectorAll(".social-filter__item");
        if (socialFilterItems) {
          Array.prototype.forEach.call(socialFilterItems, (socialFilterItem) => {
            socialFilterItem.addEventListener("click", handleSocialFilterItem);
          });
        }
      } else {
        tab.addEventListener("click", handleTabSelect);
      }

      if (linkAnchor && linkAnchor === tab.getAttribute("id")) {
        tab.click();
      }

      if (tab.checked) {
        toggleCustomFilters(tab.value);
        tab.click();
      }
    });

    selectBox.addEventListener("change", handleSelectTab);
  } else {
    // all the other variants: TabCordion, Accordion, etc.
    Array.prototype.forEach.call(tabs, (tab) => {
      // console.log("tab for TabCordion:", tab);

      addTabToSelectBox(tab);
      tab.addEventListener("click", handleClick);

      if (linkAnchor && linkAnchor === tab.getAttribute("id")) {
        // console.log("tab click -- linkAnchor:", linkAnchor);
        tab.click();
      }
    });
  }

  function initIntranetLoadMore() {
    const intranetLoadMore = document.querySelector(
      ".button--js-load-more-all"
    );
    if (intranetLoadMore) {
      // console.log("jgdebug intranetLoadMore button found");

      intranetLoadMore.addEventListener("mouseup", function (evt) {
        evt.preventDefault();
        // console.log("jgdebug countClicks 3", countClicks);

        let activeTabContent = document.querySelector(
          ".js-tab-content .is-active"
        );
        if (activeTabContent) {
          // console.log('activeTabContent found');
          fireButton(
            activeTabContent,
            false,
            countLoadMoreClicks,
            intranetLoadMore
          );
        } else {
          // console.log('activeTabContent not found');
        }
        let internalBlogTabContent = document.querySelector(
          ".js-tab-content--internal-blog"
        );
        if (internalBlogTabContent) {
          // console.log('internalBlogTabContent found');
          fireButton(
            internalBlogTabContent,
            true,
            countLoadMoreClicks,
            intranetLoadMore
          );
        } else {
          // console.log("internalBlogTabContent not found");
        }
        countLoadMoreClicks++;
      });
    }
  }

  function fireButton(
    tabContent,
    isInternalBlog,
    countClicks,
    intranetLoadMore
  ) {
    let divWrapper = tabContent.getElementsByClassName("js-load-more")[0];

    if (divWrapper) {
      // console.log('buttonWrapper found');
      let url = divWrapper.getAttribute("data-url");

      if (url !== null) {
        // console.log('url found', url);
        if (isInternalBlog) {
          url = url + "&section=intranetstartpage";
        } else {
          url = url + "&area=intranetstart";
        }
        document.querySelector(".center-children").classList.add("loading");

        // prepend protocol, hostname and port to url
        url = window.location.protocol + "//" + window.location.host + url;
        // console.log("fireButton with " + url);
        if (isInternalBlog) {
          fetch(url)
            .then((r) => r.text())
            .then((data) => renderInitialData(data, divWrapper))
            .then(() => numberOfSocials())
            .then(() => {
              document
                .querySelector(".center-children")
                .classList.remove("loading");
              // console.log("jgdebug countClicks: ", countClicks);
              if (countClicks === 2) {
                intranetLoadMore.setAttribute("style", "display:none");
                document
                  .querySelector(".button--js-new-archive-all")
                  .setAttribute("style", "display:block");
              }
            })
            .catch((e) =>
              console.error("fireButton: fetch returned error: " + e)
            );
        } else {
          fetch(url)
            .then((r) => r.text())
            .then((data) => renderInitialData(data, divWrapper))
            .then(() => {
              document
                .querySelector(".center-children")
                .classList.remove("loading");
              // console.log("jgdebug countClicks: ", countClicks);
              if (countClicks === 2) {
                intranetLoadMore.setAttribute("style", "display:none");
                document
                  .querySelector(".button--js-new-archive-all")
                  .setAttribute("style", "display:block");
              }
            })
            .catch((e) =>
              console.error("fireButton: fetch returned error: " + e)
            );
        }
      } else {
        const url = divWrapper.getAttribute("data-init-url");
        if (url !== null) {
          fetch(url)
            .then((r) => r.text())
            .then((data) => renderInitialData(data, divWrapper))
            .then(() => {
              if (countClicks === 2) {
                intranetLoadMore.setAttribute("style", "display:none");
                document
                  .querySelector(".button--js-new-archive-all")
                  .setAttribute("style", "display:block");
              }
            })
            .catch((e) =>
              console.error("fireButton: fetch returned error: " + e)
            );
        }
      }
    } else {
      // console.log("buttonWrapper not found", tabContent);
    }
  }

  function initSwiper() {
    const content = document.querySelector("#internalblog-content");
    // console.log("jgdebug cardWidth", cardWidth);
    let position = 0;
    if (content) {
      const contentWidth = -Math.abs(content.clientWidth);
      // console.log("jgdebug contentWidth", contentWidth);
      const allcards = document.querySelectorAll("#internalblog-content .card");
      let firstCard = allcards[0];
      const cardWidth = firstCard != null ? firstCard.clientWidth + 10 : 0;
      // console.log("jgdebug cardWidth", cardWidth);
      $("#internalblog-content")
        .swipeDetector()
        .on("swipeLeft.sd swipeRight.sd", function (event) {
          if (event.type === "swipeLeft") {
            if (contentWidth < 0 && position > contentWidth + cardWidth) {
              position = position - cardWidth;
              content.setAttribute("style", "left:" + position + "px");
              // console.log("jgdebug left", position);
            }
          } else if (event.type === "swipeRight") {
            if (position < 0) {
              position = position + cardWidth;
              content.setAttribute("style", "left:" + position + "px");
            }
            // console.log("jgdebug right", position);
          }
        });
    }
  }

  (function ($) {
    $.fn.swipeDetector = function (options) {
      // States: 0 - no swipe, 1 - swipe started, 2 - swipe released
      let swipeState = 0;
      // Coordinates when swipe started
      let startX = 0;
      let startY = 0;
      // Distance of swipe
      let pixelOffsetX = 0;
      let pixelOffsetY = 0;
      // Target element which should detect swipes.
      let swipeTarget = this;
      let defaultSettings = {
        // Amount of pixels, when swipe don't count.
        swipeThreshold: 30,
        // Flag that indicates that plugin should react only on touch events.
        // Not on mouse events too.
        useOnlyTouch: false,
      };

      // Initializer
      (function init() {
        const html = $("html");
        options = $.extend(defaultSettings, options);
        // Support touch and mouse as well.
        swipeTarget.on("mousedown touchstart", swipeStart);
        html.on("mouseup touchend", swipeEnd);
        html.on("mousemove touchmove", swiping);
      })();

      function swipeStart(event) {
        if (options.useOnlyTouch && !event.originalEvent.touches) return;

        if (event.originalEvent.touches) event = event.originalEvent.touches[0];

        if (swipeState === 0) {
          swipeState = 1;
          startX = event.clientX;
          startY = event.clientY;
        }
      }

      function swipeEnd() {
        if (swipeState === 2) {
          swipeState = 0;

          if (
            Math.abs(pixelOffsetX) > Math.abs(pixelOffsetY) &&
            Math.abs(pixelOffsetX) > options.swipeThreshold
          ) {
            // Horizontal Swipe
            if (pixelOffsetX < 0) {
              swipeTarget.trigger($.Event("swipeLeft.sd"));
              // console.log("Left");
            } else {
              swipeTarget.trigger($.Event("swipeRight.sd"));
              // console.log("Right");
            }
          }
        }
      }

      function swiping(event) {
        // If swipe don't occuring, do nothing.
        if (swipeState !== 1) return;

        if (event.originalEvent.touches) {
          event = event.originalEvent.touches[0];
        }

        var swipeOffsetX = event.clientX - startX;
        var swipeOffsetY = event.clientY - startY;

        if (
          Math.abs(swipeOffsetX) > options.swipeThreshold ||
          Math.abs(swipeOffsetY) > options.swipeThreshold
        ) {
          swipeState = 2;
          pixelOffsetX = swipeOffsetX;
          pixelOffsetY = swipeOffsetY;
        }
      }

      return swipeTarget; // Return element available for chaining.
    };
  })(jQuery);
}
