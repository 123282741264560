/**
 * translate a string
 *
 * label: string
 * locale: string
 *
 */
const translate = (label, locale) => {
  const translatedLabel = {
    voucher_is_not_active: {
      de_AT: "Gutschein ist nicht aktiv",
      sk_SK: "Poukážka nie je aktívna",
      hu_HU: "Az utalvány nem aktív",
      cs_CZ: "Poukázka není aktivní",
    },
    voucher_is_active: {
      de_AT: "Gutschein ist aktiv",
      sk_SK: "Poukážka je aktivovaná",
      hu_HU: "Az utalvány aktív",
      cs_CZ: "Poukázka je aktivní",
    },
    voucher_is_blocked: {
      de_AT: "Gutschein ist gesperrt",
      sk_SK: "Poukážka je zablokovaná",
      hu_HU: "Az utalvány nincs aktiválva",
      cs_CZ: "Poukázka je blokována",
    },
    voucher_is_expired: {
      de_AT: "Gutschein ist abgelaufen",
      sk_SK: "Poukážka expirovala",
      hu_HU: "Lejárt utalvány",
      cs_CZ: "Platnost poukázky vypršela",
    },
    voucher_is_exchanged: {
      de_AT: "Gutschein wurde ausgetauscht",
      sk_SK: "Poukážka bola vymenená",
      hu_HU: "Az utalványt kicserélték",
      cs_CZ: "Poukázka byla vyměněna",
    },
    voucher_in_production: {
      de_AT: "Gutscheinnummer in Produktion",
      sk_SK: "Poukážka vo výrobe",
      hu_HU: "Gyártás alatt",
      cs_CZ: "Poukázka ve výrobě",
    },
    voucher_in_redeemed: {
      de_AT: "Gutschein wurde bereits vollständig eingelöst",
      sk_SK: "Poukážka už bola uplatnená ",
      hu_HU: "Az utalványt már beváltották",
      cs_CZ: "Poukaz byl již plně uplatněn",
    },
    transaction_type_redeem: {
      de_AT: "Eingelöst",
      sk_SK: "Poukážka už bola uplatnená",
      hu_HU: "Beváltva",
      cs_CZ: "Poukázka uplatněna",
    },
    transaction_type_diagnose: {
      de_AT: "Diagnose",
      sk_SK: "Diagnóza",
      hu_HU: "Diagnózis ",
      cs_CZ: "Diagnóza",
    },
    transaction_type_activation: {
      de_AT: "Aktivierung",
      sk_SK: "Aktivácia",
      hu_HU: "Aktiválás",
      cs_CZ: "Aktivace",
    },
    transaction_type_deactivation: {
      de_AT: "Deaktivierung",
      sk_SK: "Deaktivácia",
      hu_HU: "Ideiglenes felfüggesztés",
      cs_CZ: "Deaktivace",
    },
    transaction_type_temp_deactivation: {
      de_AT: "Deaktivierung",
      sk_SK: "Blokácia",
      hu_HU: "Felfüggesztés",
      cs_CZ: "Blokace",
    },
    transaction_type_balance: {
      de_AT: "Guthabenabfrage",
      sk_SK: "Overenie zostatkového limitu",
      hu_HU: "Egyenleg lekérdezés",
      cs_CZ: "Zjištení/Oveření zůstatku",
    },
    transaction_type_refund: {
      de_AT: "Aufladung",
      sk_SK: "Nabitie kreditu",
      hu_HU: "Kredit feltöltés",
      cs_CZ: "Nabití kreditu",
    },
    transaction_type_cancel: {
      de_AT: "Stornierung",
      sk_SK: "Zrušenie",
      hu_HU: "Törlés",
      cs_CZ: "Storno",
    },
    transaction_type_preauth: {
      de_AT: "Reservierung",
      sk_SK: "Predautorizácia",
      hu_HU: "Foglalás",
      cs_CZ: "Předautorizace",
    },
    transaction_type_capture: {
      de_AT: "Eingelöst",
      sk_SK: "Uplatnené",
      hu_HU: "Beváltva",
      cs_CZ: "Uplatněno",
    },
    transaction_type_status: {
      de_AT: "Statusabfrage",
      sk_SK: "Stav poukážky",
      hu_HU: "Státusz",
      cs_CZ: "Stav poukázky",
    },
    transaction_type_confirmation: {
      de_AT: "Bestätigung",
      sk_SK: "Potvrdenie",
      hu_HU: "Visszaigazolás",
      cs_CZ: "Potvrzení",
    },
    updateExpireFeedback: {
      de_AT:
        "Das Ablaufdatum der Karte wurde verlängert. Das neue Ablaufdatum lautet:",
      sk_SK: "Platnosť poukážky bola predĺžená",
      hu_HU:
        "Az utalvány lejárati dátumát meghosszabbították. Az új lejárati dátum:",
      cs_CZ: "Platnost poukázky byla prodloužena",
    },
  };
  const transledLabel = translatedLabel[label];
  if (translatedLabel[label]) {
    return transledLabel[locale] || transledLabel["de_AT"];
  }
  return "";
};
export default translate;
